<script>
import * as d3 from 'd3';
// import pr_data from './pr_result_221.json'

export default {
  name: "GmapParcelView",
  data() {
    return {
      map: null,
      active_parcel: null,
    }
  },
  mounted() {
    document.querySelector("#app-footer").style.display = "none"
    document.querySelector("#nav_root").style.display = "none";
    (async () => {
      await initializeAfterScriptLoad.call(this);
      const q = parseJwt(this.$route.query.q);
      console.log(q)
      this.active_parcel = pr_data.tree_parcels[0].data.find(d => d.mainbookid == q.mainbookid && d.zk_bkc === q.zk_bkc)
      if (this.active_parcel) updateMap(this.map, this.active_parcel);
    })();
  },

  render() {
    return (
      <div class="GmapParcelView">
        <div class="map_cont">
          <div id="map"/>
        </div>
        <h3 class="no-map-banner">{this.active_parcel && !this.active_parcel.geometry ? 'No map data' : ''}</h3>
      </div>
    )
  }
};

// https://development.terraadriatica.hr/en/gmap-parcel-view?q=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtYWluYm9va2lkIjoiMzExNjQiLCJ6a19ia2MiOiIzOTcvMSJ9.iJ6k9fJAbqnuCgjYX24VpzaUYoq8LKhI3xomuM9JonY
// https://development.terraadriatica.hr/en/gmap-parcel-view?q=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtYWluYm9va2lkIjoiMzExNjQiLCJ6a19ia2MiOiI4NDIvNyJ9.-Jj-VFNRXIRBg0rdd7wYol0LM8yBMOdbEJXVULkXFPU
// https://development.terraadriatica.hr/en/gmap-parcel-view?q=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtYWluYm9va2lkIjoiMzExNjQiLCJ6a19ia2MiOiI4NDIvOCJ9.hrXxFMPSmHg7bKpsnlhkI5YyQp_BdamboEC9_n8l3v8

function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

function initializeAfterScriptLoad() {
  loadScript();
  return new Promise(resolve => {
    const check = () => {
      if (window.google && window.google.maps) {
        this.map = initializeMap();
        resolve()
      }
      else setTimeout(check, 300)
    }
    check()
  })

  function loadScript() {
    if (document.head.querySelector("#gmaps")) return
    const script = document.createElement("script")
    script.setAttribute('id', "gmaps")
    script.setAttribute('async', "")
    script.setAttribute('defer', "")
    script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCQ79w13s31_GReC5SQCG_mIEhuLA4DLSs"
    script.setAttribute('type', "text/javascript")
    document.head.appendChild(script);
  }

  function initializeMap() {
    return new google.maps.Map(document.getElementById("map"), {
      zoom: 7,
      center: {lat: 44, lng: 16.5},
      mapTypeId: "terrain",
    });
  }
}

function updateMap(map, d) {
  removeAllFeatures(map)
  if (!d.geometry) return

  const geoj = {type: "Feature", geometry: JSON.parse(d.geometry)},
      center = d3.geoPath().centroid(geoj)
  map.panTo({lat: center[1], lng: center[0]})
  map.data.addGeoJson(geoj);
  if (map.getZoom() !== 16) map.setZoom(16);
}

function removeAllFeatures(map) {
  map.data.forEach(feature => map.data.remove(feature));
}

</script>

<style lang="scss">
.GmapParcelView {
  .map_cont {
    height: 100vh;
    min-width: 100vw;
  }
  #map {
    width: 100%;
    height: 100%;
  }
  .no-map-banner {
    position: absolute;
    top: 20px;
    left: 20px;
  }
}
</style>
